
import { defineComponent, PropType, ref } from "vue";
import { Course } from "momai";

export default defineComponent({
  props: {
    courseList: {
      type: Array as PropType<Course[]>,
      default: () => [],
    },
    type: {
      type: String,
      required: false,
    }
  },
  setup: (props, { emit }) => {
    const hascheckedid = ref()
    return {
      hascheckedid,
      choosecourse: (courseId: any) => {
        if (hascheckedid.value !== courseId) {
          emit("changecoursecheck", courseId);
        }
      },
      changechoosestauts: (courseId: any) => {
        hascheckedid.value = Number(courseId);
      },
      resetAll: () => {
        hascheckedid.value = ''
      }
    }
  }
});
